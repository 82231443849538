import React, { useState, useEffect } from 'react';
import './Overlays.css';
import axios from 'axios';

function Overlays({ selectedDevice }) {
  const [overlays, setOverlays] = useState([]);
  const [selectedOverlay, setSelectedOverlay] = useState(null);

  // Overlay fields
  const [overlayType, setOverlayType] = useState('system');
  const [overlayName, setOverlayName] = useState('');
  const [overlayMessage, setOverlayMessage] = useState('');
  const [overlayImageURL, setOverlayImageURL] = useState('');
  const [overlayQRCodeURL, setOverlayQRCodeURL] = useState('');
  const [overlayDuration, setOverlayDuration] = useState(5);

  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [newOverlayName, setNewOverlayName] = useState('');

  const token = localStorage.getItem('token');
  const BASE_URL = process.env.REACT_APP_SOCKET_SERVER_URL || 'http://socket:5001';

  useEffect(() => {
    if (!selectedDevice) return;

    const fetchOverlays = async () => {
      try {
        // Optionally, if you want to filter by device_id on the backend,
        // you could pass ?deviceId=selectedDevice.id as a query param
        const response = await axios.get(`${BASE_URL}/api/overlays?deviceId=${selectedDevice.id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });        
        setOverlays(response.data.overlays || []);
      } catch (error) {
        console.error('Error fetching overlays:', error);
        alert('Failed to fetch overlays.');
      }
    };

    fetchOverlays();
  }, [selectedDevice, token, BASE_URL]);

  const handleOverlaySelect = (overlay) => {
    setSelectedOverlay(overlay);
    setOverlayType(overlay.type);
    setOverlayName(overlay.name || '');
    setOverlayMessage(overlay.message || '');
    setOverlayImageURL(overlay.image_url || '');
    setOverlayQRCodeURL(overlay.qr_code_url || '');
    setOverlayDuration(overlay.duration || 5);
  };

  // Determine maximum message length and whether to show message
  const isFullOverlay = overlayType === 'full';
  const systemMaxLength = 140;
  const otherMaxLength = 250;
  const messageMaxLength = overlayType === 'system' ? systemMaxLength : otherMaxLength;
  const showMessageField = !isFullOverlay; // Hide message if 'full'

  // Calculate remaining characters
  const messageRemaining = messageMaxLength - overlayMessage.length;
  if (messageRemaining < 0) {
    // If user manually overrides maxLength in dev tools, truncate
    setOverlayMessage((prev) => prev.slice(0, messageMaxLength));
  }

  const handleSaveOverlay = async () => {
    if (!selectedOverlay) return;

    let updatedMessage = overlayMessage;
    // Hard truncate if user typed beyond limit
    if (updatedMessage.length > messageMaxLength) {
      updatedMessage = updatedMessage.slice(0, messageMaxLength);
      alert(
        overlayType === 'system'
          ? `System overlay message truncated to ${systemMaxLength} characters.`
          : `Message truncated to ${otherMaxLength} characters.`
      );
    }

    const updatedOverlay = {
      ...selectedOverlay,
      name: overlayName,
      type: overlayType,
      message: updatedMessage,
      image_url: overlayType === 'system' ? null : (overlayImageURL || null),
      qr_code_url:
        overlayType === 'bottom' || overlayType === 'full'
          ? overlayQRCodeURL || null
          : null,
      duration: overlayDuration,
      device_id: selectedDevice ? selectedDevice.id : null,
    };

    try {
      await axios.put(`${BASE_URL}/api/overlays/${selectedOverlay.id}`, updatedOverlay, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setOverlays((prev) =>
        prev.map((o) => (o.id === selectedOverlay.id ? updatedOverlay : o))
      );
      alert('Overlay updated successfully!');
    } catch (error) {
      console.error('Error updating overlay:', error);
      alert('Failed to update overlay.');
    }
  };

  const handleAddNewOverlay = async () => {
    if (!newOverlayName.trim()) {
      alert('Please enter a name for the new overlay.');
      return;
    }
    if (!selectedDevice) {
      alert('No device selected. Cannot create overlay without a device.');
      return;
    }

    // Default to type=system, 7 second duration, link to device_id
    const newOverlayData = {
      name: newOverlayName.trim(),
      type: 'system',
      message: '',
      image_url: null,
      qr_code_url: null,
      duration: 7,
      device_id: selectedDevice.id,
    };

    try {
      const response = await axios.post(`${BASE_URL}/api/overlays`, newOverlayData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const createdOverlay = response.data.overlay;
      setOverlays((prev) => [...prev, createdOverlay]);
      setIsAddModalOpen(false);
      setSelectedOverlay(createdOverlay);
      setOverlayType('system');
      setOverlayName(createdOverlay.name);
      setOverlayMessage('');
      setOverlayImageURL('');
      setOverlayQRCodeURL('');
      setOverlayDuration(7);
    } catch (error) {
      console.error('Error adding new overlay:', error);
      alert('Failed to add new overlay.');
    }
  };

  const handlePlayOverlay = async () => {
    if (!selectedOverlay || !selectedOverlay.id) {
      alert('Please select a saved overlay first.');
      return;
    }
    if (!selectedDevice) {
      alert('No device selected.');
      return;
    }

    try {
      await axios.post(
        `${BASE_URL}/api/play-overlay`,
        {
          deviceId: selectedDevice.id,
          overlayId: selectedOverlay.id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      alert('Overlay played on the device!');
    } catch (error) {
      console.error('Error playing overlay:', error);
      alert('Failed to play overlay.');
    }
  };

  const handleRemoveOverlay = async () => {
    if (!selectedOverlay || !selectedOverlay.id) {
      alert('No overlay selected to remove.');
      return;
    }

    if (!window.confirm('Are you sure you want to remove this overlay?')) {
      return;
    }

    try {
      await axios.delete(`${BASE_URL}/api/overlays/${selectedOverlay.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOverlays((prev) => prev.filter((o) => o.id !== selectedOverlay.id));
      alert('Overlay removed successfully!');
      setSelectedOverlay(null);
      setOverlayType('system');
      setOverlayName('');
      setOverlayMessage('');
      setOverlayImageURL('');
      setOverlayQRCodeURL('');
      setOverlayDuration(5);
    } catch (error) {
      console.error('Error removing overlay:', error);
      alert('Failed to remove overlay.');
    }
  };

  // Determine the label and description text for the image field
  let imageLabel = 'Image or Webpage URL:';
  let imageDescription = '';
  if (overlayType === 'bottom' || overlayType === 'watermark') {
    imageLabel = 'Image URL:';
    imageDescription = 'Add a url to the image you want to display';
  } else if (overlayType === 'full') {
    imageLabel = 'Image or Webpage URL:';
    imageDescription = 'Add a url to an image or webpage, to be displayed Full Screen';
  }

  return (
    <div className="overlays-section">
      <h3>Overlays</h3>
      <div className="overlays-container">
        <div className="overlays-list">
          <div className="overlays-list-header">
            <h4>Existing Overlays</h4>
            <button onClick={() => setIsAddModalOpen(true)} className="add-button">
              Add New Overlay
            </button>
          </div>
          <ul>
            {overlays.map((overlay) => (
              <li
                key={overlay.id}
                className={
                  selectedOverlay && selectedOverlay.id === overlay.id
                    ? 'selected'
                    : ''
                }
                onClick={() => handleOverlaySelect(overlay)}
              >
                {overlay.name}
              </li>
            ))}
          </ul>
        </div>

        <div className="overlay-details">
          {selectedOverlay ? (
            <>
              <h4>Edit Overlay</h4>
              <label>
                Name:
                <input
                  type="text"
                  value={overlayName}
                  onChange={(e) => setOverlayName(e.target.value)}
                />
              </label>

              <label>
                Type:
                <select
                  value={overlayType}
                  onChange={(e) => setOverlayType(e.target.value)}
                >
                  <option value="system">System</option>
                  <option value="bottom">Bottom</option>
                  <option value="full">Full</option>
                  <option value="watermark">Watermark</option>
                </select>
              </label>

              {showMessageField && (
                <label>
                  Message ({messageRemaining} characters remaining):
                  <textarea
                    placeholder="Enter overlay message..."
                    value={overlayMessage}
                    onChange={(e) => {
                      if (e.target.value.length <= messageMaxLength) {
                        setOverlayMessage(e.target.value);
                      }
                    }}
                  />
                </label>
              )}

              {(overlayType === 'bottom' || overlayType === 'full' || overlayType === 'watermark') && (
                <label>
                  {imageLabel}
                  <small style={{ display: 'block', fontStyle: 'italic', marginBottom: '5px' }}>
                    {imageDescription}
                  </small>
                  <input
                    type="text"
                    placeholder={
                      overlayType === 'full'
                        ? 'Enter image or webpage URL...'
                        : 'Enter image URL...'
                    }
                    value={overlayImageURL}
                    onChange={(e) => setOverlayImageURL(e.target.value)}
                  />
                </label>
              )}

              {(overlayType === 'bottom' || overlayType === 'full') && (
                <label>
                  QR Code URL:
                  <input
                    type="text"
                    placeholder="Enter QR Code destination URL..."
                    value={overlayQRCodeURL}
                    onChange={(e) => setOverlayQRCodeURL(e.target.value)}
                  />
                </label>
              )}

              <label>
                Duration (seconds):
                <input
                  type="number"
                  value={overlayDuration}
                  onChange={(e) => setOverlayDuration(parseInt(e.target.value) || 1)}
                  min={1}
                />
              </label>

              <div className="overlay-buttons">
                <button onClick={handleSaveOverlay}>Save Overlay</button>
                <button
                  onClick={handlePlayOverlay}
                  disabled={!selectedOverlay || !selectedOverlay.id}
                >
                  Play Now
                </button>
                <button
                  style={{ backgroundColor: '#dc2626', color: '#fff' }}
                  onClick={handleRemoveOverlay}
                >
                  Remove Overlay
                </button>
              </div>
            </>
          ) : (
            <p>Select an overlay to edit or click "Add New Overlay".</p>
          )}
        </div>
      </div>

      {isQRCodeModalOpen && (
        <div className="qr-code-modal">
          <div className="qr-code-content">
            <h4>{overlayQRCodeURL ? 'Edit QR Code' : 'Generate QR Code'}</h4>
            <input
              type="text"
              placeholder="Enter destination URL"
              value={overlayQRCodeURL}
              onChange={(e) => setOverlayQRCodeURL(e.target.value)}
            />
            <button
              onClick={() => {
                setIsQRCodeModalOpen(false);
                alert('QR Code URL saved!');
              }}
            >
              Save
            </button>
            <button onClick={() => setIsQRCodeModalOpen(false)}>Close</button>
          </div>
        </div>
      )}

      {isAddModalOpen && (
        <div className="add-overlay-modal">
          <div className="add-overlay-content">
            <h4>Add New Overlay</h4>
            <input
              type="text"
              placeholder="Enter overlay name"
              value={newOverlayName}
              onChange={(e) => setNewOverlayName(e.target.value)}
            />
            <button onClick={handleAddNewOverlay}>Add</button>
            <button onClick={() => setIsAddModalOpen(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Overlays;
