// components/Settings/Settings.jsx

import React, { useState, useEffect } from 'react';
import './Settings.css';

function Settings({ site, area, refreshSitesAreasDevices }) {
  const [newSiteName, setNewSiteName] = useState('');
  const [newAreaName, setNewAreaName] = useState('');
  const [message, setMessage] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [deviceName, setDeviceName] = useState('');
  const [deviceType, setDeviceType] = useState('Music Player');
  const [devices, setDevices] = useState([]);
  const [showDeviceDetailsForm, setShowDeviceDetailsForm] = useState(false);
  const [deviceToken, setDeviceToken] = useState('');

  // components/Settings/Settings.jsx

useEffect(() => {
  let ignore = false; // local variable to detect if the component is unmounted

  const fetchDevices = async () => {
    if (!area) return;
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`/api/areas/${area.id}/devices`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!ignore && response.ok) {
        const data = await response.json();
        if (!ignore) {
          setDevices(data.devices || []);
        }
      } else if (!ignore) {
        console.error('Failed to load devices.');
      }
    } catch (error) {
      if (!ignore) {
        console.error('Error fetching devices:', error);
      }
    }
  };

  fetchDevices();

  return () => {
    ignore = true; // Mark component as unmounted
  };
}, [area]);


  // Handle verifying the device
  const handleVerifyDevice = async () => {
    if (!verificationCode) {
      setMessage('Please enter the verification code.');
      return;
    }
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Authentication error: Please log in again.');
      return;
    }

    try {
      const response = await fetch('/api/devices/verify-device', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` 
        },
        body: JSON.stringify({
          code: verificationCode,
        }),
      });

      const result = await response.json();
      if (response.ok && result.success) {
        setMessage('');
        setShowDeviceDetailsForm(true);
        setDeviceToken(result.token);
      } else {
        setMessage(result.message || 'Failed to verify device.');
      }
    } catch (error) {
      alert('An error occurred while verifying the device.');
    }
  };


  // Handle saving the device
  const handleSaveDevice = async () => {
    if (!deviceName || !deviceType) {
      setMessage('Please enter device name and select device type.');
      return;
    }
    if (!area) {
      setMessage('Please select an area.');
      return;
    }
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Authentication error: Please log in again.');
      return;
    }

    try {
      const response = await fetch('/api/devices/save-device', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` 
        },
        body: JSON.stringify({
          token: deviceToken,
          deviceName,
          deviceType,
          areaId: area.id,
        }),
      });

      const result = await response.json();
      if (response.ok && result.success) {
        alert('Device registered successfully! Press play on the device to get started');
        setDeviceName('');
        setDeviceType('Music Player');
        setVerificationCode('');
        setShowDeviceDetailsForm(false);
        setDeviceToken('');
        refreshSitesAreasDevices(site.id);
      } else {
        setMessage(result.message || 'Failed to register device.');
      }
    } catch (error) {
      alert('An error occurred while saving the device.');
    }
  };

  // Handle forgetting a device
  const handleForgetDevice = async (deviceId) => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Authentication error: Please log in again.');
      return;
    }

    try {
      const response = await fetch('/api/devices/forget-device', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` 
        },
        body: JSON.stringify({ deviceId }),
      });

      const data = await response.json();

      if (response.ok && data.success) {
        setDevices(devices.filter((device) => device.id !== deviceId));
        alert('Device forgotten successfully.');
        refreshSitesAreasDevices(site.id);
      } else {
        alert(`Failed to forget device: ${data.message || 'Unknown error.'}`);
      }
    } catch (error) {
      alert('An error occurred while forgetting the device.');
    }
  };

  // Add a new site
  const handleAddSite = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('/api/sites', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ name: newSiteName || 'New Site' }),
      });

      if (response.ok) {
        const data = await response.json();
        setNewSiteName('');
        setMessage('Site added successfully!');
        refreshSitesAreasDevices(data.siteId);
      } else {
        const errorData = await response.json();
        setMessage(errorData.message || 'Failed to add site.');
      }
    } catch (error) {
      setMessage('An error occurred while adding a site.');
    }
  };

  // Rename site
  const handleRenameSite = async () => {
    if (!site) {
      setMessage('Please select a site to rename.');
      return;
    }

    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`/api/sites/${site.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ name: newSiteName }),
      });

      if (response.ok) {
        setNewSiteName('');
        setMessage('Site renamed successfully!');
        refreshSitesAreasDevices(site.id);
      } else {
        const errorData = await response.json();
        setMessage(errorData.message || 'Failed to rename site.');
      }
    } catch (error) {
      setMessage('An error occurred while renaming the site.');
    }
  };

  // Add a new area
  const handleAddArea = async () => {
    const token = localStorage.getItem('token');
    if (!site) {
      setMessage('Please select a site before adding an area.');
      return;
    }

    try {
      const response = await fetch(`/api/areas`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ siteId: site.id, name: newAreaName }),
      });

      if (response.ok) {
        const data = await response.json();
        setNewAreaName('');
        setMessage('Area added successfully!');
        refreshSitesAreasDevices(site.id);
      } else {
        const errorData = await response.json();
        setMessage(errorData.message || 'Failed to add area.');
      }
    } catch (error) {
      setMessage('An error occurred while adding an area.');
    }
  };

  // Rename area
  const handleRenameArea = async () => {
    if (!area) {
      setMessage('Please select an area to rename.');
      return;
    }

    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`/api/areas/${area.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ name: newAreaName }),
      });

      if (response.ok) {
        setNewAreaName('');
        setMessage('Area renamed successfully!');
        refreshSitesAreasDevices(site.id);
      } else {
        const errorData = await response.json();
        setMessage(errorData.message || 'Failed to rename area.');
      }
    } catch (error) {
      setMessage('An error occurred while renaming the area.');
    }
  };

  return (
    <div className="settings">
      <div className="site-area-actions">
        <h3>Site Actions</h3>
        <input
          type="text"
          value={newSiteName}
          onChange={(e) => setNewSiteName(e.target.value)}
          placeholder="New Site Name"
        />
        <button onClick={handleAddSite}>Add Site</button>
        <button onClick={handleRenameSite}>Rename Site</button>

        <h3>Area Actions</h3>
        <input
          type="text"
          value={newAreaName}
          onChange={(e) => setNewAreaName(e.target.value)}
          placeholder="New Area Name"
        />
        <button onClick={handleAddArea}>Add Area</button>
        <button onClick={handleRenameArea}>Rename Area</button>
      </div>

      <div className="register-device">
        <h3>Register a New Device</h3>
        <h6>Visit https://soundscreen.soundcheckvn.com/connect on another device to get your Verification code</h6>
        {!showDeviceDetailsForm ? (
          <>
            <input
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder="Enter Verification Code"
              required
            />
            <button onClick={handleVerifyDevice}>Verify Device</button>
            {message && <p className="message">{message}</p>}
          </>
        ) : (
          <>
            <p className="message">Verified! Please enter device details below.</p>
            <input
              type="text"
              value={deviceName}
              onChange={(e) => setDeviceName(e.target.value)}
              placeholder="Device Name"
              required
            />
            <select
              value={deviceType}
              onChange={(e) => setDeviceType(e.target.value)}
            >
              <option value="Music Player">Music Player</option>
              <option value="Staff">Staff</option>
              <option value="TV">TV</option>
              <option value="Stage Screen">Stage Screen</option>
            </select>
            <button onClick={handleSaveDevice}>Save Device</button>
            {message && <p className="message">{message}</p>}
          </>
        )}
      </div>

      {/* Device List */}
      <div className="device-list">
        <h3>Devices in Selected Area</h3>
        {devices.length > 0 ? (
          <ul>
            {devices.map((device) => (
              <li key={device.id}>
                {device.name} - {device.type}
                <button onClick={() => handleForgetDevice(device.id)}>Forget Device</button>
              </li>
            ))}
          </ul>
        ) : (
          <p>No devices in this area.</p>
        )}
      </div>
    </div>
  );
}

export default Settings;
