// components/Announcements/Announcements.jsx

import React, { useState, useEffect, useRef } from 'react';
import './Announcements.css';
import axios from 'axios';

// Map of language codes to user-friendly labels:
const LANGUAGE_LABELS = {
  'en-AU': 'English - Australia',
  'en-GB': 'English - Great Britain',
  'en-US': 'English - United States',
  'en-CA': 'English - Canada',
  'en-IN': 'English - India',
  // Add more as needed...
};

function Announcements({ selectedDevice }) {
  const token = localStorage.getItem('token');

  // Announcement list + form fields
  const [announcements, setAnnouncements] = useState([]);
  const [announcementId, setAnnouncementId] = useState(null);

  // We'll track the "mode" with a boolean or string:
  // "create" vs "edit"
  const [mode, setMode] = useState('create');

  const [title, setTitle] = useState('');
  const [customAnnouncementText, setCustomAnnouncementText] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedVoice, setSelectedVoice] = useState('');

  // TTS voices
  const [availableVoices, setAvailableVoices] = useState([]);
  const [uniqueLanguages, setUniqueLanguages] = useState([]);

  // For storing old title if user changes it in edit mode
  const oldTitleRef = useRef('');

  // 1) On mount, fetch existing announcements from DB
  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        console.log('Announcement token in localStorage:', token);
        const response = await axios.get('/api/announcements', {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log('→ Received announcements:', response.data); // <-- Add this
        setAnnouncements(response.data);
      } catch (error) {
        console.error('Error fetching announcements:', error);
      }
    };
    fetchAnnouncements();

    // We want to start in "create" mode
    setMode('create');
    setAnnouncementId(null);
    setTitle('');
    setCustomAnnouncementText('');
    setSelectedLanguage('');
    setSelectedVoice('');
  }, [token]);

  // 2) Fetch TTS voices
  useEffect(() => {
    const fetchVoices = async () => {
      try {
        const response = await axios.get('/api/tts/voices', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const voicesData = response.data || [];
        setAvailableVoices(voicesData);

        const allLangs = new Set();
        voicesData.forEach((voice) => {
          voice.languageCodes.forEach((code) => allLangs.add(code));
        });
        setUniqueLanguages(Array.from(allLangs));
      } catch (error) {
        console.error('Error fetching voices:', error);
      }
    };
    fetchVoices();
  }, [token]);

  // Filter voices by selectedLanguage
  const filteredVoices = availableVoices.filter((voice) =>
    voice.languageCodes.includes(selectedLanguage)
  );

  // Handle selecting an existing announcement
  const handleAnnouncementSelect = (announcement) => {
    setMode('edit');
    setAnnouncementId(announcement.id);
    setTitle(announcement.title || '');
    setCustomAnnouncementText(announcement.content || '');
    setSelectedLanguage(announcement.language_code || '');
    setSelectedVoice(announcement.voice_name || '');

    // Save old title in case user changes it
    oldTitleRef.current = announcement.title || '';
  };

  // Create new
  const handleCreateNew = () => {
    setMode('create');
    setAnnouncementId(null);
    setTitle('');
    setCustomAnnouncementText('');
    setSelectedLanguage('');
    setSelectedVoice('');
  };

  // If user changes the title in edit mode, we might prompt them
  const handleTitleChange = (newTitle) => {
    // Only do logic if we are in "edit" mode and the user had an old title
    if (mode === 'edit' && oldTitleRef.current && oldTitleRef.current !== newTitle) {
      // The user is effectively renaming the announcement
      const proceed = window.confirm(
        'You have changed the announcement title. Click OK to rename the existing record. Click Cancel to create a new announcement with this new title.'
      );
      if (proceed) {
        // They want to rename existing
        setTitle(newTitle);
      } else {
        // They want to create a new announcement
        // so we forcibly switch to create mode
        setMode('create');
        setAnnouncementId(null);
      }
    } else {
      // normal case
      setTitle(newTitle);
    }
  };

  // Preview TTS (no saving)
  const handlePreviewAnnouncement = async () => {
    if (!customAnnouncementText.trim()) {
      alert('Please enter text to preview!');
      return;
    }
    if (!selectedLanguage) {
      alert('Please select a language!');
      return;
    }
    if (!selectedVoice) {
      alert('Please select a voice!');
      return;
    }

    try {
      const response = await fetch('/api/tts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          text: customAnnouncementText,
          languageCode: selectedLanguage,
          voiceName: selectedVoice,
        }),
      });
      if (!response.ok) throw new Error('Failed to generate speech.');

      const blob = await response.blob();
      const audioURL = URL.createObjectURL(blob);
      new Audio(audioURL).play();
    } catch (error) {
      console.error('Error generating speech:', error);
      alert('Error generating speech. Check console for details.');
    }
  };

  // Save or Update
  const handleSaveAnnouncement = async () => {
    if (!title.trim()) {
      alert('Please enter a title!');
      return;
    }
    if (!customAnnouncementText.trim()) {
      alert('Please enter some text!');
      return;
    }
    if (!selectedLanguage || !selectedVoice) {
      alert('Please select language and voice!');
      return;
    }

    const body = {
      id: announcementId || null,
      title,
      content: customAnnouncementText,
      languageCode: selectedLanguage,
      voiceName: selectedVoice,
      deviceId: selectedDevice ? selectedDevice.id : null,
    };

    try {
      let url, method;
      if (mode === 'edit' && announcementId) {
        // Overwrite existing
        url = `/api/announcements/${announcementId}`;
        method = 'PUT';
      } else {
        // Create new
        url = '/api/announcements';
        method = 'POST';
      }

      const response = await axios({
        url,
        method,
        headers: { Authorization: `Bearer ${token}` },
        data: body,
      });
      alert(response.data.message || 'Announcement saved!');

      // Refresh list
      const newList = await axios.get('/api/announcements', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAnnouncements(newList.data);

      // If newly created, store returned ID
      if (method === 'POST' && response.data.id) {
        setAnnouncementId(response.data.id);
        setMode('edit');
        oldTitleRef.current = title;
      }
    } catch (error) {
      console.error('Error saving announcement:', error);
      alert('Failed to save announcement. Check console for details.');
    }
  };

  // Delete
  const handleDeleteAnnouncement = async () => {
    if (!announcementId || mode !== 'edit') {
      return;
    }
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this announcement? This cannot be undone.'
    );
    if (!confirmDelete) return;

    try {
      await axios.delete(`/api/announcements/${announcementId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('Announcement deleted successfully!');

      // Refresh announcements
      const newList = await axios.get('/api/announcements', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAnnouncements(newList.data);

      // Clear form, switch to create mode
      setMode('create');
      setAnnouncementId(null);
      setTitle('');
      setCustomAnnouncementText('');
      setSelectedLanguage('');
      setSelectedVoice('');
    } catch (error) {
      console.error('Error deleting announcement:', error);
      alert('Failed to delete announcement. Check console for details.');
    }
  };

  // Helpers
  const getLanguageLabel = (code) => LANGUAGE_LABELS[code] || code;
  const getVoiceDisplayName = (voiceName) => {
    if (selectedLanguage && voiceName.startsWith(selectedLanguage + '-')) {
      return voiceName.replace(selectedLanguage + '-', '');
    }
    return voiceName;
  };

  return (
    <div className="announcements-section">
      <h3>Announcements</h3>
      <div className="announcements-container">
        {/* Left side: Announcement List */}
        <div className="announcements-list">
          <h4>Existing Announcements</h4>
          <ul>
            {announcements.map((ann) => (
              <li
                key={ann.id}
                className={announcementId === ann.id && mode === 'edit' ? 'selected' : ''}
                onClick={() => handleAnnouncementSelect(ann)}
              >
                {ann.title}
              </li>
            ))}
          </ul>
          <button onClick={handleCreateNew}>Create New Announcement</button>
        </div>

        {/* Right side: Form */}
        <div className="announcement-details">
          <h4>{mode === 'edit' ? 'Edit Announcement' : 'Create Announcement'}</h4>
          <input
            type="text"
            placeholder="Enter announcement title..."
            value={title}
            onChange={(e) => handleTitleChange(e.target.value)}
            style={{ width: '100%', marginBottom: '10px' }}
          />
          <textarea
            placeholder="Enter announcement text here..."
            value={customAnnouncementText}
            onChange={(e) => setCustomAnnouncementText(e.target.value)}
          />

          <label>
            Language:
            <select
              value={selectedLanguage}
              onChange={(e) => {
                setSelectedLanguage(e.target.value);
                setSelectedVoice('');
              }}
            >
              <option value="">-- Select a Language --</option>
              {uniqueLanguages.map((lang) => (
                <option key={lang} value={lang}>
                  {getLanguageLabel(lang)}
                </option>
              ))}
            </select>
          </label>

          <label>
            Voice:
            <select
              value={selectedVoice}
              onChange={(e) => setSelectedVoice(e.target.value)}
              disabled={!selectedLanguage}
            >
              <option value="">-- Select a Voice --</option>
              {filteredVoices.map((voice) => (
                <option key={voice.name} value={voice.name}>
                  {getVoiceDisplayName(voice.name)}
                </option>
              ))}
            </select>
          </label>

          <div className="announcement-buttons">
            <button onClick={handleSaveAnnouncement}>
              {mode === 'edit' ? 'Save' : 'Create'}
            </button>

            <button
              onClick={handlePreviewAnnouncement}
              style={{ backgroundColor: '#6B7280' }}
            >
              Preview
            </button>

            {/* Show delete button only in edit mode */}
            {mode === 'edit' && announcementId && (
              <button
                className="delete-button"
                onClick={handleDeleteAnnouncement}
              >
                Delete
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Announcements;
