import React from 'react';
import MediaManager from '../MediaManager/MediaManager';

function MediaManagerSection({
  handleAddToAdHocPlaylist,
  handleLoadToUpNext,
  adHocPlaylist,
  deviceType,
  onSavePlaylist,
  onDeletePlaylist,
  onLoadSavedPlaylist,
  savedPlaylists,
  existingPlaylist,
  playlistName,
  showLoadToUpNextButton
}) {
  return (
    <div className="media-manager-section">
      <MediaManager
        playlistName={playlistName}
        adHocMode={true}
        existingPlaylist={adHocPlaylist}
        onUpdatePlaylist={handleAddToAdHocPlaylist} 
        onLoadToUpNext={handleLoadToUpNext}
        deviceType={deviceType}
        categoryFixed={false}
        onSavePlaylist={onSavePlaylist}
        onDeletePlaylist={onDeletePlaylist}
        onLoadSavedPlaylist={onLoadSavedPlaylist}
        savedPlaylists={savedPlaylists}
        showLoadToUpNextButton={showLoadToUpNextButton}
      />
    </div>
  );
}

export default MediaManagerSection;
