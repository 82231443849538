import React, { useEffect, useState } from 'react';
import './AreaOverview.css';

function AreaOverview({ area, onDeviceSelect }) {
  const [devices, setDevices] = useState([]);
  const [deviceStates, setDeviceStates] = useState({});

  useEffect(() => {
    let ignore = false; // Local variable to detect if the component is unmounted

    const fetchDeviceConnectionAndState = async (deviceId, token) => {
      if (ignore) return; // stop if unmounted
      try {
        const res = await fetch(`/api/deck-states?deviceId=${deviceId}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });

        if (ignore) return; // stop if unmounted

        if (res.ok) {
          const data = await res.json();
          // Check if the response indicates a connected device
          // success: true means we got a state (possibly empty)
          // deckA, deckB might be undefined if no state available, which is fine.
          setDeviceStates((prev) => ({
            ...prev,
            [deviceId]: {
              connected: true,
              state: {
                deckA: data.deckA,
                deckB: data.deckB,
                isPlaying: data.isPlaying,
                isMuted: data.isMuted,
                masterVolume: data.masterVolume,
                crossfadePosition: data.crossfadePosition,
                isMixing: data.isMixing,
                playerSettings: data.playerSettings
              }
            }
          }));
        } else if (res.status === 404) {
          // Device not connected or no state available from socket server
          setDeviceStates((prev) => ({
            ...prev,
            [deviceId]: { connected: false, state: null }
          }));
        } else {
          console.error(`Failed to fetch deck states for device ${deviceId}, status: ${res.status}`);
          setDeviceStates((prev) => ({
            ...prev,
            [deviceId]: { connected: false, state: null }
          }));
        }
      } catch (err) {
        console.error('Error fetching device state:', err);
        if (!ignore) {
          setDeviceStates((prev) => ({
            ...prev,
            [deviceId]: { connected: false, state: null }
          }));
        }
      }
    };

    const fetchDevices = async () => {
      if (!area) return;
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found. Please log in again.');
        return;
      }

      try {
        const response = await fetch(`/api/areas/${area.id}/devices`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (ignore) return; // stop if unmounted

        if (response.ok) {
          const data = await response.json();
          const loadedDevices = data.devices || [];
          setDevices(loadedDevices);

          for (let dev of loadedDevices) {
            if (ignore) return; // stop if unmounted
            await fetchDeviceConnectionAndState(dev.id, token);
          }
        } else {
          console.error('Failed to load devices.');
        }
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    };

    fetchDevices();

    return () => {
      ignore = true; // Mark component as unmounted
    };
  }, [area]);

  const formatTime = (seconds) => {
    if (!seconds || seconds < 0) return '00:00';
    const h = Math.floor(seconds / 3600).toString().padStart(2, '0');
    const m = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
    const s = Math.floor(seconds % 60).toString().padStart(2, '0');
    return h !== '00' ? `${h}:${m}:${s}` : `${m}:${s}`;
  };

  return (
    <div className="area-overview">
      <h2>{area ? `${area.name} Overview` : 'Area Overview'}</h2>
      {devices.length > 0 ? (
        <div className="device-cards">
          {devices.map((device) => {
            const deviceInfo = deviceStates[device.id] || {};
            const { connected, state } = deviceInfo;
            const connectionColor = connected ? 'green' : 'red';

            const deviceTypeDisplay = device.type || 'Unknown';

            let nowPlayingTitle = 'None';
            let nowPlayingTime = '';
            if (state && state.deckA && state.deckA.title) {
              nowPlayingTitle = state.deckA.title;
              nowPlayingTime = `Time: ${formatTime(state.deckA.currentTime)} / ${formatTime(state.deckA.duration)}`;
            }

            return (
              <div key={device.id} className="device-card" onClick={() => onDeviceSelect(device.id)}>
                <p style={{ marginBottom: '5px', fontWeight: 'normal' }}>
                  {deviceTypeDisplay}
                </p>
                <p style={{ fontWeight: 'bold', margin: '5px 0' }}>
                  {device.name}
                  <span
                    style={{
                      display: 'inline-block',
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      backgroundColor: connectionColor,
                      marginLeft: '10px',
                      verticalAlign: 'middle',
                    }}
                    title={connected ? 'Connected' : 'Disconnected'}
                  ></span>
                </p>

                <div className="now-playing-info" style={{ margin: '10px 0' }}>
                  <p style={{ margin: '5px 0' }}>
                    Now Playing: {nowPlayingTitle}
                  </p>
                  {nowPlayingTime && (
                    <p style={{ margin: '5px 0' }}>
                      {nowPlayingTime}
                    </p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <p>No devices found in this area.</p>
      )}
    </div>
  );
}

export default AreaOverview;
