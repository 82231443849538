import React, { useState, useEffect } from 'react';
import './ControlPanel.css';
import Settings from '../../components/Settings';
import AreaOverview from '../../components/AreaOverview';
import Dashboard from '../../components/Dashboard';
import Scheduler from '../../components/Scheduler';
import PlaylistLibrary from '../../components/PlaylistLibrary';
import FallbackPlaylist from '../../components/FallbackPlaylist';
import Overlays from '../../components/Overlays';
import Announcements from '../../components/Announcements';
import Events from '../../components/Events';

function ControlPanel() {
  const [site, setSite] = useState(null);
  const [sites, setSites] = useState([]);
  const [area, setArea] = useState(null);
  const [areas, setAreas] = useState([]);
  const [device, setDevice] = useState(null);
  const [devices, setDevices] = useState([]);
  const [deviceType, setDeviceType] = useState('');
  const [selectedTab, setSelectedTab] = useState('Settings');

  const getAvailableTabs = (deviceType) => {
    const tabs = [
      'Area Overview',
      'Dashboard',
      'Scheduler',
      'Playlist Library',
      'Fallback Playlist',
    ];

    if (deviceType === 'TV') {
      tabs.push('Overlays');
    }

    if (deviceType === 'Music Player') {
      tabs.push('Announcements');
    }

    tabs.push('Events');
    tabs.push('Settings');

    return tabs;
  };

  useEffect(() => {
    const fetchSitesAndAreas = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch('/api/sites', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          const fetchedSites = data.sites || [];
          setSites(fetchedSites);

          if (fetchedSites.length > 0) {
            const firstSite = fetchedSites[0];
            setSite(firstSite);

            const areasResponse = await fetch(`/api/sites/${firstSite.id}/areas`, {
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            });

            if (areasResponse.ok) {
              const areasData = await areasResponse.json();
              setAreas(areasData.areas || []);

              if (areasData.areas && areasData.areas.length > 0) {
                const firstArea = areasData.areas[0];
                setArea(firstArea);
                fetchDevices(firstArea.id);
              } else {
                setArea(null);
                setDevices([]);
                setDevice(null);
                setDeviceType('');
                setSelectedTab('Settings');
              }
            }
          }
        } else {
          console.error('Failed to load sites.');
        }
      } catch (error) {
        console.error('Error fetching sites:', error);
      }
    };

    fetchSitesAndAreas();
  }, []);

  const fetchDevices = async (areaId) => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`/api/areas/${areaId}/devices`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const devs = data.devices || [];
        setDevices(devs);

        if (devs.length > 0) {
          const firstDevice = devs[0];
          setDevice(firstDevice);
          setDeviceType(firstDevice.type);
          // Default to Area Overview if we have devices
          setSelectedTab('Area Overview');

          const availableTabs = getAvailableTabs(firstDevice.type);
          if (!availableTabs.includes(selectedTab)) {
            if (firstDevice.type === 'TV') {
              setSelectedTab('Overlays');
            } else if (firstDevice.type === 'Music Player') {
              setSelectedTab('Announcements');
            } else {
              setSelectedTab('Area Overview');
            }
          }
        } else {
          setDevice(null);
          setDeviceType('');
          setSelectedTab('Settings');
        }
      } else {
        console.error('Failed to load devices.');
        setDevice(null);
        setDeviceType('');
        setSelectedTab('Settings');
      }
    } catch (error) {
      console.error('Error fetching devices:', error);
      setDevice(null);
      setDeviceType('');
      setSelectedTab('Settings');
    }
  };

  const handleSiteChange = async (siteId) => {
    const updatedSite = sites.find((s) => s.id === siteId);
    setSite(updatedSite);

    if (updatedSite) {
      setAreas([]);
      setArea(null);
      setDevices([]);
      setDevice(null);
      setDeviceType('');
      setSelectedTab('Settings');

      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`/api/sites/${siteId}/areas`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setAreas(data.areas || []);

          if (data.areas && data.areas.length > 0) {
            const firstArea = data.areas[0];
            setArea(firstArea);
            fetchDevices(firstArea.id);
          } else {
            setArea(null);
            setDevices([]);
            setDevice(null);
            setDeviceType('');
            setSelectedTab('Settings');
          }
        } else {
          console.error('Failed to fetch areas.');
          setSelectedTab('Settings');
        }
      } catch (error) {
        console.error('Error fetching areas:', error);
        setSelectedTab('Settings');
      }
    } else {
      setAreas([]);
      setDevices([]);
      setDevice(null);
      setDeviceType('');
      setSelectedTab('Settings');
    }
  };

  const handleAreaChange = async (areaId) => {
    const selectedArea = areas.find((a) => a.id === areaId);
    setArea(selectedArea);

    if (selectedArea) {
      fetchDevices(selectedArea.id);
    } else {
      setDevices([]);
      setDevice(null);
      setDeviceType('');
      setSelectedTab('Settings');
    }
  };

  const handleDeviceChange = (deviceId) => {
    const selectedDevice = devices.find((d) => d.id === deviceId);
    setDevice(selectedDevice);
    setDeviceType(selectedDevice?.type || '');

    if (selectedDevice) {
      const availableTabs = getAvailableTabs(selectedDevice.type);
      if (!availableTabs.includes(selectedTab)) {
        if (selectedDevice.type === 'TV') {
          setSelectedTab('Overlays');
        } else if (selectedDevice.type === 'Music Player') {
          setSelectedTab('Announcements');
        } else {
          setSelectedTab('Area Overview');
        }
      }
    } else {
      setDeviceType('');
      setSelectedTab('Settings');
    }
  };

  const handleDeviceSelectFromAreaOverview = (deviceId) => {
    handleDeviceChange(deviceId);
    setSelectedTab('Dashboard');
  };

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  const refreshSitesAreasDevices = async (siteId) => {
    await handleSiteChange(siteId);
  };

  useEffect(() => {
    if (device) {
      const availableTabs = getAvailableTabs(deviceType);

      if (!availableTabs.includes(selectedTab)) {
        if (deviceType === 'TV') {
          setSelectedTab('Overlays');
        } else if (deviceType === 'Music Player') {
          setSelectedTab('Announcements');
        } else {
          setSelectedTab('Area Overview');
        }
      }
    } else {
      if (selectedTab !== 'Settings') {
        setSelectedTab('Settings');
      }
    }
  }, [device, deviceType, selectedTab]);

  const renderTabs = () => {
    const tabs = [];

    if (!device) {
      tabs.push(
        <button
          key="Settings"
          className={`tab-button ${selectedTab === 'Settings' ? 'active' : ''}`}
          onClick={() => handleTabClick('Settings')}
          style={{ marginLeft: 'auto' }}
        >
          Settings
        </button>
      );
      return tabs;
    }

    tabs.push(
      <button
        key="Area Overview"
        className={`tab-button ${selectedTab === 'Area Overview' ? 'active' : ''}`}
        onClick={() => handleTabClick('Area Overview')}
      >
        Area Overview
      </button>
    );

    tabs.push(
      <button
        key="Dashboard"
        className={`tab-button ${selectedTab === 'Dashboard' ? 'active' : ''}`}
        onClick={() => handleTabClick('Dashboard')}
      >
        Dashboard
      </button>
    );

    tabs.push(
      <button
        key="Scheduler"
        className={`tab-button ${selectedTab === 'Scheduler' ? 'active' : ''}`}
        onClick={() => handleTabClick('Scheduler')}
      >
        Scheduler
      </button>
    );

    tabs.push(
      <button
        key="Playlist Library"
        className={`tab-button ${selectedTab === 'Playlist Library' ? 'active' : ''}`}
        onClick={() => handleTabClick('Playlist Library')}
      >
        Playlist Library
      </button>
    );

    tabs.push(
      <button
        key="Fallback Playlist"
        className={`tab-button ${selectedTab === 'Fallback Playlist' ? 'active' : ''}`}
        onClick={() => handleTabClick('Fallback Playlist')}
      >
        Fallback Playlist
      </button>
    );

    if (deviceType === 'TV') {
      tabs.push(
        <button
          key="Overlays"
          className={`tab-button ${selectedTab === 'Overlays' ? 'active' : ''}`}
          onClick={() => handleTabClick('Overlays')}
        >
          Overlays
        </button>
      );
    }

    if (deviceType === 'Music Player') {
      tabs.push(
        <button
          key="Announcements"
          className={`tab-button ${selectedTab === 'Announcements' ? 'active' : ''}`}
          onClick={() => handleTabClick('Announcements')}
        >
          Announcements
        </button>
      );
    }

    tabs.push(
      <button
        key="Events"
        className={`tab-button ${selectedTab === 'Events' ? 'active' : ''}`}
        onClick={() => handleTabClick('Events')}
      >
        Events
      </button>
    );

    tabs.push(
      <button
        key="Settings"
        className={`tab-button ${selectedTab === 'Settings' ? 'active' : ''}`}
        onClick={() => handleTabClick('Settings')}
        style={{ marginLeft: 'auto' }}
      >
        Settings
      </button>
    );

    return tabs;
  };

  return (
    <div className="control-panel">
      <h1>Manager's Control Panel</h1>

      <div className="selectors-row">
        <div className="selector-item">
          <label>Site</label>
          <select
            value={site?.id || ''}
            onChange={(e) => handleSiteChange(parseInt(e.target.value))}
          >
            <option value="" disabled>
              Select a site
            </option>
            {sites.map((site) => (
              <option key={site.id} value={site.id}>
                {site.name}
              </option>
            ))}
          </select>
        </div>

        <div className="selector-item">
          <label>Area</label>
          <select
            value={area?.id || ''}
            onChange={(e) => handleAreaChange(parseInt(e.target.value))}
          >
            <option value="" disabled>
              Select an area
            </option>
            {areas.map((area) => (
              <option key={area.id} value={area.id}>
                {area.name}
              </option>
            ))}
          </select>
        </div>

        <div className="selector-item">
          <label>Device</label>
          <select
            value={device?.id || ''}
            onChange={(e) => handleDeviceChange(parseInt(e.target.value))}
          >
            <option value="" disabled>
              Select a device
            </option>
            {devices.map((device) => (
              <option key={device.id} value={device.id}>
                {device.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="tabs">{renderTabs()}</div>

      <div className="tab-content">
        {selectedTab === 'Settings' && (
          <Settings site={site} area={area} refreshSitesAreasDevices={refreshSitesAreasDevices} />
        )}
        {selectedTab === 'Area Overview' && area && (
          <AreaOverview area={area} onDeviceSelect={handleDeviceSelectFromAreaOverview} />
        )}
        {selectedTab === 'Dashboard' && device && (
          <Dashboard device={device} deviceType={deviceType} />
        )}
        {selectedTab === 'Scheduler' && device && <Scheduler device={device} />}
        {selectedTab === 'Playlist Library' && device && (
          <PlaylistLibrary selectedDevice={device} />
        )}
        {selectedTab === 'Fallback Playlist' && device && (
          <FallbackPlaylist selectedDevice={device} />
        )}
        {selectedTab === 'Overlays' && device && deviceType === 'TV' && (
          <Overlays selectedDevice={device} />
        )}
        {selectedTab === 'Announcements' && device && deviceType === 'Music Player' && (
          <Announcements selectedDevice={device} />
        )}
        {selectedTab === 'Events' && (
          <Events />
        )}
        {device == null && selectedTab !== 'Settings' && (
          <p>No devices found in this area. Please select a device.</p>
        )}
      </div>
    </div>
  );
}

export default ControlPanel;
